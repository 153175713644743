<template>
  <v-container class="text-center px-0 pa-0" fluid>

    <b-card no-body class="transparent">
      <b-card-header header-tag="header" v-b-toggle.accordion5 :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" role="tab">
        <v-row>
          <v-col cols="12" sm="5" class="text-left">
            <div class="pt-5 d-block">
              {{ this.$t('generic.lang_preview') }}
            </div>
          </v-col>
          <v-col cols="12" sm="7" class="pa-5 text-right pr-3">
            <v-btn @click="device = 'tablet'" icon depressed :color="this.device === 'tablet'? 'primary' : ''" class="">
              <v-icon class="">tablet</v-icon>
            </v-btn>
            <v-btn @click="device = 'mobile'" :color="this.device === 'mobile'? 'primary' : ''" icon depressed class="">
              <v-icon class="">smartphone</v-icon>
            </v-btn>

            <v-btn icon depressed color="grey" class="">
              <v-icon @click="copyDomain" class="mx-3">content_copy</v-icon>
            </v-btn>

            <v-btn @click="openDomain" icon depressed color="grey" class="">
              <v-icon class="">open_in_new</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </b-card-header>


      <b-card-body class="pa-0">
        <v-progress-circular indeterminate size="50" color="primary" v-if="this.loading"/>
        <v-fab-transition v-else>
          <div v-if="this.tableBeeDomain && this.device === 'mobile'" class="py-3">
            <div class="smartphone">
              <div class="content">
                <iframe style="width:100%;border:none;height:100%"
                        :src="'https://' + this.tableBeeDomain.domain + '/table/1'"/>
              </div>
            </div>
          </div>
          <div v-else-if="this.tableBeeDomain && this.device === 'tablet'" class="py-3">
            <div class="tablet">
              <div class="content">
                <iframe style="width:100%;border:none;height:100%"
                        :src="this.preview_url"/>
              </div>
            </div>
          </div>
        </v-fab-transition>
      </b-card-body>
    </b-card>

  </v-container>
</template>

<script>
import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";


export default {
  name: "tableBeeDesignPreviewComponent",
  data() {
    return {
      hasDomains: false,
      domains: null,
      loading: false,
      tables: [],
      tableBeeDomain: null,
      device: "mobile"
    }
  },
  methods: {
    openDomain() {
      if (this.tableBeeDomain) {
        let domain = this.tableBeeDomain.domain;
        let a = document.createElement('a');
        a.href = 'https://' + domain;
        a.target = '_blank';
        a.click();

        a.remove();
      }
    },
    copyDomain() {
      if (this.tableBeeDomain) {
        let domain = this.tableBeeDomain.domain;
        let self = this;
        navigator.clipboard.writeText(domain).then(function () {
          self.$bvToast.toast(domain, {
            title: self.$t('generic.lang_domainCopied'),
            variant: 'info',
            solid: true
          })
        }, function (err) {
          this.$bvToast.toast(domain, {
            title: self.$t('generic.lang_domainCopied'),
            variant: 'danger',
            solid: true
          })
        });
      }
    },
    resizeIframe(obj) {
      obj.style.height = obj.contentWindow.document.documentElement.scrollHeight + 'px';
    },

    loadTables() {
      this.loading = true;
      this.axios.post(ENDPOINTS.POS.GASTRO.TABLES.GETALL)
          .then(res => {
            this.tables = [...res.data.tables];
          }).catch(err => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.loading = false;
      });
    },
    loadSubdomains() {
      this.loading = true;
      this.axios.post(ENDPOINTS.SETTINGS.BASIC.SYSTEMSETTINGS.CASHIER.SUBDOMAIN.GET, this.form).then((res) => {
        if (res.data.STATUS === 'SUCCESS') {
          this.hasDomains = res.data.hasDomains;

          if (Array.isArray(res.data.domains) && res.data.domains.length > 0) {
            this.domains = [...res.data.domains];

            this.tableBeeDomain = this.domains.find((domain) => domain.service === "tablebee")
          } else {
            this.domains = null;
            this.tableBeeDomain = null;
          }
        } else if (res.data.status === 'FAILED') {
          Events.$emit("showSnackbar", {
            message: res.data.msg,
            color: "error"
          });
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.loading = false;
      })
    },
  },
  computed: {
    preview_url() {
      if (Array.isArray(this.tables) && this.tables.length > 0) {
        let table = this.tables.find((t) => t.tablebee_uuid !== "" && t.tablebee_uuid !== null)
        if (table) return 'https://' + this.tableBeeDomain.domain + '/table/' + table.tablebee_uuid;
      }

      return 'https://' + this.tableBeeDomain.domain;
    }
  },
  mounted() {
    this.loadSubdomains();
    this.loadTables();
  }
}
</script>

<style scoped>
/* The device with borders */
.smartphone {
  position: relative;
  width: 360px;
  height: 640px;
  margin: auto;
  border: 16px black solid;
  border-top-width: 60px;
  border-bottom-width: 60px;
  border-radius: 36px;
}

/* The horizontal line on the top of the device */
.smartphone:before {
  content: '';
  display: block;
  width: 60px;
  height: 5px;
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #333;
  border-radius: 10px;
}

/* The circle on the bottom of the device */
.smartphone:after {
  content: '';
  display: block;
  width: 35px;
  height: 35px;
  position: absolute;
  left: 50%;
  bottom: -65px;
  transform: translate(-50%, -50%);
  background: #333;
  border-radius: 50%;
}

/* The screen (or content) of the device */
.smartphone .content {
  width: 328px;
  height: 526px;
  background: white;
}


/* The device with borders */
.tablet {
  position: relative;
  width: 768px;
  height: 1024px;
  margin: auto;
  border: 16px black solid;
  border-top-width: 60px;
  border-bottom-width: 60px;
  border-radius: 36px;
}

/* The horizontal line on the top of the device */
.tablet:before {
  content: '';
  display: block;
  width: 60px;
  height: 5px;
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #333;
  border-radius: 10px;
}

/* The circle on the bottom of the device */
.tablet:after {
  content: '';
  display: block;
  width: 35px;
  height: 35px;
  position: absolute;
  left: 50%;
  bottom: -65px;
  transform: translate(-50%, -50%);
  background: #333;
  border-radius: 50%;
}

/* The screen (or content) of the device */
.tablet .content {
  width: 740px;
  height: 910px;
  background: white;
  margin: -1px;
}
</style>
