<template>
  <b-card no-body class="mb-1 transparent">
    <b-card-header header-tag="header" v-b-toggle.accordion1 :class="[this.$vuetify.theme.dark? 'dark-bg' : '', 'p-1']"  role="tab">
      <v-row>
        <v-col>
          <div class="pa-5 d-block">
            {{ $t('tablebee.lang_theme_colors') }}
          </div>
        </v-col>
        <v-col align="end" class="pa-5 ">
          <v-btn depressed color="success" @click="updateColor" :loading="loadig">
            {{ $t('generic.lang_save') }}
          </v-btn>
        </v-col>
      </v-row>

    </b-card-header>
    <b-card-body>
      <v-row>

        <v-col cols="12" sm="6">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on" class="main-card mb-3 card shadow-sm">
                <div class="pa-0 ma-0 dropdown-menu-header">

                  <div class="card-header bg-info text-white">
                    {{ $t('tablebee.lang_primary_color') }}
                  </div>
                </div>
                <div class="card-body pa-0">
                  <v-color-picker
                      mode="hexa"
                      v-model="color.primary"
                      class="ma-0"
                      width="100%"
                      hide-canvas
                      show-swatches
                      swatches-max-height="100"
                  ></v-color-picker>
                </div>
              </div>
            </template>
            <span>{{ this.$t('tablebee.lang_primary_color') }}</span>
          </v-tooltip>
        </v-col>
        <v-col cols="12" sm="6">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on" class="main-card mb-3 card shadow-sm">
                <div class="pa-0 ma-0 dropdown-menu-header">

                  <div class="card-header bg-info text-white">
                    {{ $t('tablebee.lang_secondary_color') }}
                  </div>
                </div>
                <div class="card-body pa-0">
                  <v-color-picker mode="hexa"
                                  v-model="color.secondary"
                                  class="ma-0"
                                  width="100%"
                                  hide-canvas
                                  show-swatches
                                  swatches-max-height="100"
                  ></v-color-picker>
                </div>
              </div>
            </template>
            <span>{{ this.$t('tablebee.lang_secondary_color') }}</span>
          </v-tooltip>
        </v-col>
        <v-col cols="12" sm="6">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on" class="main-card mb-3 card shadow-sm">
                <div class="pa-0 ma-0 dropdown-menu-header">

                  <div class="card-header bg-info text-white">
                    {{ $t('generic.lang_backgroundcolor') }}
                  </div>
                </div>
                <div class="card-body pa-0">
                  <v-color-picker mode="hexa"
                                  v-model="color.background"
                                  class="ma-0"
                                  width="100%"
                                  hide-canvas
                                  show-swatches
                                  swatches-max-height="100"
                  ></v-color-picker>
                </div>
              </div>
            </template>
            <span>{{ this.$t('generic.lang_backgroundcolor') }}</span>
          </v-tooltip>
        </v-col>
        <v-col cols="12" sm="6">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on" class="main-card mb-3 card shadow-sm">
                <div class="pa-0 ma-0 dropdown-menu-header">

                  <div class="card-header bg-info text-white">
                    {{ $t('tablebee.lang_tertiary_color') }}
                  </div>
                </div>
                <div class="card-body pa-0">
                  <v-color-picker mode="hexa"
                                  v-model="color.tertiary"
                                  class="ma-0"
                                  width="100%"
                                  hide-canvas
                                  show-swatches
                                  swatches-max-height="100"
                  ></v-color-picker>
                </div>
              </div>
            </template>
            <span>{{ this.$t('tablebee.lang_tertiary_color') }}</span>
          </v-tooltip>
        </v-col>
        <v-col cols="12" sm="6">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on" class="main-card mb-3 card shadow-sm">
                <div class="pa-0 ma-0 dropdown-menu-header">

                  <div class="card-header bg-info text-white">
                    {{ $t('tablebee.lang_accent_color') }}
                  </div>
                </div>
                <div class="card-body pa-0">
                  <v-color-picker mode="hexa"
                                  v-model="color.accent"
                                  class="ma-0"
                                  width="100%"
                                  hide-canvas
                                  show-swatches
                                  swatches-max-height="100"
                  ></v-color-picker>
                </div>
              </div>
            </template>
            <span>{{ this.$t('tablebee.lang_accent_color') }}</span>
          </v-tooltip>
        </v-col>
        <v-col cols="12" sm="6">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on" class="main-card mb-3 card shadow-sm">
                <div class="pa-0 ma-0 dropdown-menu-header">

                  <div class="card-header bg-info text-white">
                    {{ $t('generic.lang_productCardBackground') }}
                  </div>
                </div>
                <div class="card-body pa-0">
                  <v-color-picker mode="hexa"
                                  v-model="color.product_card"
                                  class="ma-0"
                                  width="100%"
                                  hide-canvas
                                  show-swatches
                                  swatches-max-height="100"
                  ></v-color-picker>
                </div>
              </div>
            </template>
            <span>{{ this.$t('generic.lang_productCardBackground') }}</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";

export default {
  name: "tableBeeColorsComponent",
  data() {
    return {
      color: {
        primary: null,
        secondary: null,
        background: null,
        tertiary: null,
        accent: null,
        product_card: null,
      },
      loadig: false
    }
  },
  methods: {
    updateColor() {
      this.loadig = true;
      this.axios.post(ENDPOINTS.DELIVERY.SETTINGS.LOCALBEE.SELFSERVICE.DESIGN.COLOR.UPDATE, {
        color: this.color
      }).then(res => {
        if (res.status === 200) {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_actionSuccessful'),
            color: "success"
          });
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch(err => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred') + ' ' + err.message,
          color: "error"
        });
      }).finally(() => {
        this.loadig = false;
      })
    },
    getColor() {
      this.axios.post(ENDPOINTS.DELIVERY.SETTINGS.LOCALBEE.SELFSERVICE.DESIGN.COLOR.GET)
          .then(res => {
            if (res.status === 200) {
              this.color = res.data.color
            } else {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_errorOccurred'),
                color: "error"
              });
            }
          }).catch(err => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred') + ' ' + err.message,
          color: "error"
        });
      })
    }
  },
  mounted() {
    this.getColor();
  }
}
</script>

<style scoped>
.dropdown-menu-header {
  z-index: 1 !important;
}
</style>
