<template>
  <div>
    <page-title :heading="$t('delivery.lang_tablebeeDesign') "
                :subheading="$t('delivery.lang_tablebeeDesign')" :icon=icon></page-title>
    <div class="app-main__inner">
      <table-bee-design-component/>
    </div>
  </div>
</template>

<script>
import PageTitle from "../../Layout/Components/PageTitle.vue";
import TableBeeDesignComponent from "@/components/tableBee/design/tableBeeDesignComponent";

export default {
  name: "tableBeeDesignView",
  components: {
    TableBeeDesignComponent,
    PageTitle,
  },

  data: () => ({
    icon: 'pe-7s-config icon-gradient bg-tempting-azure',
  })
}
</script>
