<template>
  <v-card class="transparent elevation-0">
    <v-card-title
      :class="[this.$vuetify.theme.dark ? 'dark-bg' : '']"
      class="card-header"
    >
      {{ this.$t("generic.lang_nav_help") }}
    </v-card-title>

    <v-card-text>
      <div class="my-3">
        <a href="https://hilfe.3pos.de/docs/design-einstellungen/" target="_blank">
          https://hilfe.3pos.de/docs/design-einstellungen/
        </a>
      </div>

      <div>
        <!-- here you can add text -->
      </div>
    </v-card-text>

    <v-card-text>
      <v-img
        src="@/assets/images/tablebee/TableBee-Farben-komprimiert_Seite_1.png"
        contain
        style="margin-top: 10px; margin-bottom: 15px"
      ></v-img>
      <v-img
        src="@/assets/images/tablebee/TableBee-Farben-komprimiert_Seite_2.png"
        contain
        style="margin-top: 10px; margin-bottom: 15px"
      ></v-img>
      <v-img
        src="@/assets/images/tablebee/TableBee-Farben-komprimiert_Seite_3.png"
        contain
        style="margin-top: 10px; margin-bottom: 15px"
      ></v-img>
      <v-img
        src="@/assets/images/tablebee/TableBee-Farben-komprimiert_Seite_4.png"
        contain
        style="margin-top: 10px; margin-bottom: 15px"
      ></v-img>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "tableBeeDesignHelpComponent",
};
</script>
