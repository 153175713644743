<template>
  <b-card no-body class="mb-1 transparent">
    <b-card-header header-tag="header" v-b-toggle.accordion2 :class="[this.$vuetify.theme.dark? 'dark-bg' : '', 'p-1']" role="tab">
      <v-row>
        <v-col>
          <div class="pa-5 d-block">
            {{ this.$t('tablebee.lang_text_settings') }}
          </div>
        </v-col>
        <v-col align="end" class="pa-5 ">
          <v-btn depressed color="success" @click="updateText" :loading="loadingLayout">
            {{ this.$t('generic.lang_save') }}
          </v-btn>
        </v-col>
      </v-row>
    </b-card-header>
    <b-card-body>
      <v-row>
        <v-col cols="12">
          <v-select
              v-model="texts.fontFamily"
              :items="data.available_fonts"
              :label="this.$t('tablebee.lang_font_family')"
              outlined
              dense
          >
            <template v-slot:item="{ item }">
              <span :style="{fontFamily : item}">{{ item }}</span>

            </template>
          </v-select>
        </v-col>
        <v-col cols="12" sm="6">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on" class="main-card mb-3 card shadow-sm">
                <div class="pa-0 ma-0 dropdown-menu-header">

                  <div class="card-header header-items bg-info text-white">
                    <H1>{{ $t('generic.lang_headline1') }}</H1>
                  </div>
                </div>
                <div class="card-body pa-0">
                  <v-color-picker mode="hexa"
                                  v-model="texts.headLine1"
                                  class="ma-0"
                                  width="100%"
                                  hide-canvas
                                  show-swatches
                                  swatches-max-height="100"
                  ></v-color-picker>
                </div>
              </div>
            </template>
            <span>{{ $t('generic.lang_headline1') + ' ' + $t('erp.lang_warecreate_color') }}</span>
          </v-tooltip>
        </v-col>
        <v-col cols="12" sm="6">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on" class="main-card mb-3 card shadow-sm">
                <div class="pa-0 ma-0 dropdown-menu-header">

                  <div class="card-header  header-items bg-info text-white">
                    <H2>{{ $t('generic.lang_headline2') }}</H2>
                  </div>
                </div>
                <div class="card-body pa-0">
                  <v-color-picker mode="hexa"
                                  v-model="texts.headLine2"
                                  class="ma-0"
                                  width="100%"
                                  hide-canvas
                                  show-swatches
                                  swatches-max-height="100"
                  ></v-color-picker>
                </div>
              </div>
            </template>
            <span>{{ $t('generic.lang_headline2') + ' ' + $t('erp.lang_warecreate_color') }}</span>
          </v-tooltip>
        </v-col>
        <v-col cols="12" sm="6">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on" class="main-card mb-3 card shadow-sm">
                <div class="pa-0 ma-0 dropdown-menu-header">

                  <div class="card-header bg-info text-white">
                    <h3>{{ $t('generic.lang_headline3') }} </h3>
                  </div>
                </div>
                <div class="card-body pa-0">
                  <v-color-picker mode="hexa"
                                  v-model="texts.headLine3"
                                  class="ma-0"
                                  width="100%"
                                  hide-canvas
                                  show-swatches
                                  swatches-max-height="100"
                  ></v-color-picker>
                </div>
              </div>
            </template>
            <span>{{ $t('generic.lang_headline3') + ' ' + $t('erp.lang_warecreate_color') }}</span>
          </v-tooltip>
        </v-col>
        <v-col cols="12" sm="6">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on" class="main-card mb-3 card shadow-sm">
                <div class="pa-0 ma-0 dropdown-menu-header">

                  <div class="card-header bg-info text-white">
                    <h4>{{ $t('generic.lang_headline4') }} </h4>
                  </div>
                </div>
                <div class="card-body pa-0">
                  <v-color-picker mode="hexa"
                                  v-model="texts.headLine4"
                                  class="ma-0"
                                  width="100%"
                                  hide-canvas
                                  show-swatches
                                  swatches-max-height="100"
                  ></v-color-picker>
                </div>
              </div>
            </template>
            <span>{{ $t('generic.lang_headline4') + ' ' + $t('erp.lang_warecreate_color') }}</span>
          </v-tooltip>
        </v-col>
        <v-col cols="12" sm="6">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on" class="main-card mb-3 card shadow-sm">
                <div class="pa-0 ma-0 dropdown-menu-header">

                  <div class="card-header bg-info text-white">
                    <h5>{{ $t('generic.lang_headline5') }}</h5>
                  </div>
                </div>
                <div class="card-body pa-0">
                  <v-color-picker mode="hexa"
                                  v-model="texts.headLine5"
                                  class="ma-0"
                                  width="100%"
                                  hide-canvas
                                  show-swatches
                                  swatches-max-height="100"
                  ></v-color-picker>
                </div>
              </div>
            </template>
            <span>{{ $t('generic.lang_headline5') + ' ' + $t('erp.lang_warecreate_color') }}</span>
          </v-tooltip>
        </v-col>
        <v-col cols="12" sm="6">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on" class="main-card mb-3 card shadow-sm">
                <div class="pa-0 ma-0 dropdown-menu-header">

                  <div class="card-header bg-info text-white">
                    <H6>{{ $t('generic.lang_headline6') }}</H6>
                  </div>
                </div>
                <div class="card-body pa-0">
                  <v-color-picker mode="hexa"
                                  v-model="texts.headLine6"
                                  class="ma-0"
                                  width="100%"
                                  hide-canvas
                                  show-swatches
                                  swatches-max-height="100"
                  ></v-color-picker>
                </div>
              </div>
            </template>
            <span>{{ $t('generic.lang_headLineColor') }}</span>
          </v-tooltip>
        </v-col>
        <v-col cols="12" sm="6">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on" class="main-card mb-3 card shadow-sm">
                <div class="pa-0 ma-0 dropdown-menu-header">

                  <div class="card-header bg-info text-white">
                    {{ $t('generic.lang_subheader') }}
                  </div>
                </div>
                <div class="card-body pa-0">
                  <v-color-picker mode="hexa"
                                  v-model="texts.subHeader"
                                  class="ma-0"
                                  width="100%"
                                  hide-canvas
                                  show-swatches
                                  swatches-max-height="100"
                  ></v-color-picker>
                </div>
              </div>
            </template>
            <span>{{ $t('generic.lang_subHeaderColor') }}</span>
          </v-tooltip>
        </v-col>
        <v-col cols="12" sm="6">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on" class="main-card mb-3 card shadow-sm">
                <div class="pa-0 ma-0 dropdown-menu-header">

                  <div class="card-header bg-info text-white">
                    {{ $t('generic.lang_product_name') }}
                  </div>
                </div>
                <div class="card-body pa-0">
                  <v-color-picker mode="hexa"
                                  v-model="texts.productName"
                                  class="ma-0"
                                  width="100%"
                                  hide-canvas
                                  show-swatches
                                  swatches-max-height="100"
                  ></v-color-picker>
                </div>
              </div>
            </template>
            <span>{{ $t('generic.lang_productNameColor') }}</span>
          </v-tooltip>
        </v-col>
        <v-col cols="12" sm="6">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on" class="main-card mb-3 card shadow-sm">
                <div class="pa-0 ma-0 dropdown-menu-header">

                  <div class="card-header bg-info text-white">
                    {{ $t('generic.lang_product_description') }}
                  </div>
                </div>
                <div class="card-body pa-0">
                  <v-color-picker mode="hexa"
                                  v-model="texts.productDescription"
                                  class="ma-0"
                                  width="100%"
                                  hide-canvas
                                  show-swatches
                                  swatches-max-height="100"
                  ></v-color-picker>
                </div>
              </div>
            </template>
            <span>{{ $t('generic.lang_productDescriptionColor') }}</span>
          </v-tooltip>
        </v-col>
        <v-col cols="12" sm="6">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on" class="main-card mb-3 card shadow-sm">
                <div class="pa-0 ma-0 dropdown-menu-header">

                  <div class="card-header bg-info text-white">
                    {{ $t('generic.lang_product_price') }}
                  </div>
                </div>
                <div class="card-body pa-0">
                  <v-color-picker mode="hexa"
                                  v-model="texts.productPrice"
                                  class="ma-0"
                                  width="100%"
                                  hide-canvas
                                  show-swatches
                                  swatches-max-height="100"
                  ></v-color-picker>
                </div>
              </div>
            </template>
            <span>{{ $t('generic.lang_productPriceColor') }}</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </b-card-body>

  </b-card>
</template>

<script>
import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";

export default {
  name: "tableBeeTextColorsComponent",
  data() {
    return {
      loadingLayout: false,
      texts: {
        hideProductDetails: false,
        fontFamily: null,
        headLine1: null,
        headLine2: null,
        headLine3: null,
        headLine4: null,
        headLine5: null,
        headLine6: null,
        subHeader: null,
        productName: null,
        productDescription: null,
        productPrice: null
      },
      data: {
        available_fonts: [
          'Arial',
          'Verdana',
          'Helvetica',
          'Tahoma',
          'Trebuchet MS',
          'Times New Roman',
          'Georgia',
          'Garamond',
          "Pacifico",
          "Dancing Script",
          "Shadows Into Light",
          "Lobster",
          "Anton",
          "Indie Flower",
          "Charmonman",
          "Kodchasan",
          "Notable",
          "Mali",
          "Srisakdi",
          "Slabo 27px"
        ]
      },
    }
  },
  methods: {
    getText() {
      this.axios.post(ENDPOINTS.DELIVERY.SETTINGS.LOCALBEE.SELFSERVICE.DESIGN.TEXT.GET)
          .then(res => {
            if (res.status === 200) {
              this.texts = res.data.texts
            } else {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_errorOccurred'),
                color: "error"
              });
            }
          }).catch(err => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred') + ' ' + err.message,
          color: "error"
        });
      })
    },
    updateText() {
      this.loadingLayout = true;
      this.axios.post(ENDPOINTS.DELIVERY.SETTINGS.LOCALBEE.SELFSERVICE.DESIGN.TEXT.UPDATE, {
        texts: this.texts
      }).then(res => {
        if (res.status === 200) {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_actionSuccessful'),
            color: "success"
          });
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch(err => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred') + ' ' + err.message,
          color: "error"
        });
      }).finally(() => {
        this.loadingLayout = false;
      })
    },
  },
  mounted() {
    this.getText();
  }
}
</script>

<style scoped>
.header-items {
  z-index: 1 !important;
}
</style>
