<template>
  <v-container class="pa-0" fluid>
    <v-tabs v-model="tab">
      <v-tab>
        {{$t('generic.lang_productCard')}}
      </v-tab>
    </v-tabs>

    <v-tabs-items class="pa-3" v-model="tab">
      <v-tab-item>
        <product-card-component/>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import ProductCardComponent from "@/components/tableBee/design/extraCss/productCardComponent";

export default {
  name: "tableBeeExtraCssComponent",
  components: {ProductCardComponent},
  data: () => ({
    tab: 0,

  }),
  computed: {},
  methods: {}
}
</script>

<style scoped>

</style>
