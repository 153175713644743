<template>
	<v-container class="px-0" fluid>
		<b-card no-body class="mb-1 transparent">
			<b-card-header
				header-tag="header"
				v-b-toggle.accordion4
				:class="[this.$vuetify.theme.dark ? 'dark-bg' : '', 'p-1']"
				role="tab"
			>
				<v-row>
					<v-col>
						<div class="pa-5 d-block">
							{{ this.$t("generic.lang_gui_messages") }}
						</div>
					</v-col>
					<v-col align="end" class="pa-5">
						<v-btn depressed color="success" @click="updateMessages" :loading="loadingLayout">
							{{ $t("generic.lang_save") }}
						</v-btn>
					</v-col>
				</v-row>
			</b-card-header>
			<b-card-body>
				<b-tabs v-if="this.isTranslationEnabled">
					<b-tab active>
						<template #title>
							<v-btn text class="ma-0">
								<country-flag country="DE" />
							</v-btn>
						</template>

						<v-row class="pa-0" no-gutters>
							<v-col class="" cols="12">
								<v-subheader><strong>Nachricht auf der Willkommensseite</strong></v-subheader>
								<quill-editor
									id="editor1"
									:data-layout="KEYBOARD.KEYSETS.NORMAL"
									:options="quillOptions"
									@focus="showTouchKeyboard"
									output="html"
									class="mb-10"
									v-model="messages.startPage"
								>
								</quill-editor>
							</v-col>
							<v-col class="pt-5" cols="12">
								<v-subheader><strong>Nach der Nachricht auf der Namensseite fragen </strong> </v-subheader>
								<quill-editor
									id="editor2"
									:data-layout="KEYBOARD.KEYSETS.NORMAL"
									:options="quillOptions"
									@focus="showTouchKeyboard"
									output="html"
									class="mb-10"
									v-model="messages.nameMsg"
								>
								</quill-editor>
							</v-col>
							<v-col cols="12" class="pt-5">
								<v-subheader><strong>Nachrichtenmenü</strong></v-subheader>
								<quill-editor
									id="editor3"
									:data-layout="KEYBOARD.KEYSETS.NORMAL"
									:options="quillOptions"
									@focus="showTouchKeyboard"
									output="html"
									class="mb-10"
									v-model="messages.menuMsg"
								>
								</quill-editor>
							</v-col>

							<v-col cols="12" class="pt-5">
								<v-subheader><strong>Kopfzeile Zahlungsauswahl</strong></v-subheader>
								<quill-editor
									id="editor3"
									:data-layout="KEYBOARD.KEYSETS.NORMAL"
									:options="quillOptions"
									@focus="showTouchKeyboard"
									output="html"
									class="mb-10"
									v-model="messages.selectPaymentMsg"
								>
								</quill-editor>
							</v-col>
						</v-row>
					</b-tab>

					<b-tab>
						<template #title>
							<v-btn text class="ma-0">
								<country-flag country="GB" />
							</v-btn>
						</template>

						<v-row class="pa-0" no-gutters>
							<v-col class="" cols="12">
								<v-subheader><strong>Welcome Page Message</strong></v-subheader>
								<quill-editor
									id="editor3"
									:data-layout="KEYBOARD.KEYSETS.NORMAL"
									:options="quillOptions"
									@focus="showTouchKeyboard"
									output="html"
									class="mb-10"
									v-model="messages.startPage_en"
								>
								</quill-editor>
							</v-col>
							<v-col class="pt-5" cols="12">
								<v-subheader><strong>Ask For Name Page Message </strong></v-subheader>
								<quill-editor
									id="editor4"
									:data-layout="KEYBOARD.KEYSETS.NORMAL"
									:options="quillOptions"
									@focus="showTouchKeyboard"
									output="html"
									class="mb-10"
									v-model="messages.nameMsg_en"
								>
								</quill-editor>
							</v-col>
							<v-col cols="12" class="pt-5">
								<v-subheader><strong>Menu Message</strong></v-subheader>
								<quill-editor
									id="editor3"
									:data-layout="KEYBOARD.KEYSETS.NORMAL"
									:options="quillOptions"
									@focus="showTouchKeyboard"
									output="html"
									class="mb-10"
									v-model="messages.menuMsg_en"
								>
								</quill-editor>
							</v-col>

							<v-col cols="12" class="pt-5">
								<v-subheader><strong>Payment selection header</strong></v-subheader>
								<quill-editor
									id="editor3"
									:data-layout="KEYBOARD.KEYSETS.NORMAL"
									:options="quillOptions"
									@focus="showTouchKeyboard"
									output="html"
									class="mb-10"
									v-model="messages.selectPaymentMsg_en"
								>
								</quill-editor>
							</v-col>
						</v-row>
					</b-tab>

					<b-tab>
						<template #title>
							<v-btn text class="ma-0">
								<country-flag country="FR" />
							</v-btn>
						</template>

						<v-row class="pa-0" no-gutters>
							<v-col class="" cols="12">
								<v-subheader><strong>Message de la page de bienvenue</strong></v-subheader>
								<quill-editor
									id="editor5"
									:data-layout="KEYBOARD.KEYSETS.NORMAL"
									:options="quillOptions"
									@focus="showTouchKeyboard"
									output="html"
									class="mb-10"
									v-model="messages.startPage_fr"
								>
								</quill-editor>
							</v-col>
							<v-col class="pt-5" cols="12">
								<v-subheader><strong>Le message du page de nom </strong></v-subheader>
								<quill-editor
									id="editor6"
									:data-layout="KEYBOARD.KEYSETS.NORMAL"
									:options="quillOptions"
									@focus="showTouchKeyboard"
									output="html"
									class="mb-10"
									v-model="messages.nameMsg_fr"
								>
								</quill-editor>
							</v-col>
							<v-col cols="12" class="pt-5">
								<v-subheader><strong>Menu Message</strong></v-subheader>
								<quill-editor
									id="editor6"
									:data-layout="KEYBOARD.KEYSETS.NORMAL"
									:options="quillOptions"
									@focus="showTouchKeyboard"
									output="html"
									class="mb-10"
									v-model="messages.menuMsg_fr"
								>
								</quill-editor>
							</v-col>

							<v-col cols="12" class="pt-5">
								<v-subheader><strong>En-tête de sélection de paiement</strong></v-subheader>
								<quill-editor
									id="editor6"
									:data-layout="KEYBOARD.KEYSETS.NORMAL"
									:options="quillOptions"
									@focus="showTouchKeyboard"
									output="html"
									class="mb-10"
									v-model="messages.selectPaymentMsg_fr"
								>
								</quill-editor>
							</v-col>
						</v-row>
					</b-tab>

					<b-tab>
						<template #title>
							<v-btn text class="ma-0">
								<country-flag country="SA" />
							</v-btn>
						</template>

						<v-row class="pa-0 text-right" no-gutters>
							<v-col class="" cols="12">
								<v-subheader><strong>رسالة صفحة الترحيب</strong></v-subheader>
								<quill-editor
									id="editor7"
									:data-layout="KEYBOARD.KEYSETS.NORMAL"
									:options="quillOptions"
									@focus="showTouchKeyboard"
									output="html"
									class="mb-10"
									v-model="messages.startPage_ar"
								>
								</quill-editor>
							</v-col>
							<v-col class="pt-5" cols="12">
								<v-subheader><strong>رسالة صفحة إدخال الاسم </strong></v-subheader>
								<quill-editor
									id="editor8"
									:data-layout="KEYBOARD.KEYSETS.NORMAL"
									:options="quillOptions"
									@focus="showTouchKeyboard"
									output="html"
									class="mb-10"
									v-model="messages.nameMsg_ar"
								>
								</quill-editor>
							</v-col>
							<v-col cols="12" class="pt-5">
								<v-subheader><strong>رسالة قائمة المنتجات </strong></v-subheader>

								<quill-editor
									id="editor7"
									:data-layout="KEYBOARD.KEYSETS.NORMAL"
									:options="quillOptions"
									@focus="showTouchKeyboard"
									output="html"
									class="mb-10"
									v-model="messages.menuMsg_ar"
								>
								</quill-editor>
							</v-col>

							<v-col cols="12" class="pt-5">
								<v-subheader><strong>اختيار الدفع</strong></v-subheader>

								<quill-editor
									id="editor7"
									:data-layout="KEYBOARD.KEYSETS.NORMAL"
									:options="quillOptions"
									@focus="showTouchKeyboard"
									output="html"
									class="mb-10"
									v-model="messages.selectPaymentMsg_ar"
								>
								</quill-editor>
							</v-col>
						</v-row>
					</b-tab>
				</b-tabs>

				<v-row v-else class="pa-0" no-gutters>
					<v-col class="" cols="12">
						<v-subheader
							><strong>{{ $t("generic.lang_messageOnTheWelcomePage") }}</strong></v-subheader
						>
						<quill-editor
							id="editor1"
							:data-layout="KEYBOARD.KEYSETS.NORMAL"
							:options="quillOptions"
							@focus="showTouchKeyboard"
							output="html"
							class="mb-10"
							v-model="messages.startPage"
						>
						</quill-editor>
					</v-col>
					<v-col class="pt-5" cols="12">
						<v-subheader
							><strong>{{ $t("generic.lang_askForTheMessageOnTheNamePage") }}</strong>
						</v-subheader>
						<quill-editor
							id="editor2"
							:data-layout="KEYBOARD.KEYSETS.NORMAL"
							:options="quillOptions"
							@focus="showTouchKeyboard"
							output="html"
							class="mb-10"
							v-model="messages.nameMsg"
						>
						</quill-editor>
					</v-col>
					<v-col class="pt-5">
						<v-subheader
							><strong>{{ $t("generic.lang_messagesMenu") }}</strong></v-subheader
						>

						<quill-editor
							id="editor2"
							:data-layout="KEYBOARD.KEYSETS.NORMAL"
							:options="quillOptions"
							@focus="showTouchKeyboard"
							output="html"
							class="mb-10"
							v-model="messages.menuMsg"
						>
						</quill-editor>
					</v-col>
				</v-row>
			</b-card-body>
		</b-card>

		<div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
			<vue-touch-keyboard
				:accept="hideTouchKeyboard"
				:cancel="hideTouchKeyboard"
				:defaultKeySet="touchKeyboard.keySet"
				:input="touchKeyboard.input"
				:layout="touchKeyboard.layout"
				:options="touchKeyboard.options"
				class="internalWidth"
				id="onScreenKeyboard"
				v-if="touchKeyboard.visible"
			/>
		</div>
	</v-container>
</template>

<script>
import mixin from "../../../mixins/KeyboardMixIns";
import { ENDPOINTS } from "@/config";
import { Events } from "@/plugins/events";
import { createNamespacedHelpers } from "vuex";
import CountryFlag from "vue-country-flag";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import { quillOptions } from "@/plugins/quillSetup.js";

export default {
	name: "tableBeeDesignMessagesComponent",
	mixins: [mixin],
	components: {
		CountryFlag,
		quillEditor,
	},
	data() {
		return {
			loadingLayout: false,
			quillOptions: quillOptions,
			messages: {
				startPage: "",
				nameMsg: "",
				menuMsg: "",
				selectPaymentMsg: "",
				//en
				startPage_en: "",
				nameMsg_en: "",
				menuMsg_en: "",
				selectPaymentMsg_en: "",
				//fr
				startPage_fr: "",
				nameMsg_fr: "",
				menuMsg_fr: "",
				selectPaymentMsg_fr: "",
				//ar
				startPage_ar: "",
				nameMsg_ar: "",
				menuMsg_ar: "",
				selectPaymentMsg_ar: "",
			},
		};
	},
	computed: {
		...createNamespacedHelpers("settings").mapGetters(["getSettingValue"]),
		isTranslationEnabled() {
			return parseInt(this.getSettingValue("enable_translation")) === 1;
		},
	},
	methods: {
		getMessages() {
			this.axios
				.post(ENDPOINTS.DELIVERY.SETTINGS.LOCALBEE.SELFSERVICE.DESIGN.MESSAGES.GET)
				.then((res) => {
					if (res.status === 200) {
						this.messages = res.data.messages;
					} else {
						Events.$emit("showSnackbar", {
							message: this.$t("generic.lang_errorOccurred"),
							color: "error",
						});
					}
				})
				.catch((err) => {
					Events.$emit("showSnackbar", {
						message: this.$t("generic.lang_errorOccurred") + " " + err.message,
						color: "error",
					});
				});
		},
		updateMessages() {
			this.loadingLayout = true;
			this.axios
				.post(ENDPOINTS.DELIVERY.SETTINGS.LOCALBEE.SELFSERVICE.DESIGN.MESSAGES.UPDATE, {
					messages: this.messages,
				})
				.then((res) => {
					if (res.status === 200) {
						Events.$emit("showSnackbar", {
							message: this.$t("generic.lang_actionSuccessful"),
							color: "success",
						});
					} else {
						Events.$emit("showSnackbar", {
							message: this.$t("generic.lang_errorOccurred"),
							color: "error",
						});
					}
				})
				.catch((err) => {
					Events.$emit("showSnackbar", {
						message: this.$t("generic.lang_errorOccurred") + " " + err.message,
						color: "error",
					});
				})
				.finally(() => {
					this.loadingLayout = false;
				});
		},
	},
	mounted() {
		this.getMessages();
	},
};
</script>

<style scoped>
.quill-editor {
	height: 300px !important;
}
</style>
